/* import __COLOCATED_TEMPLATE__ from './setup.hbs'; */
/* RESPONSIBLE TEAM: team-customer-data-platform */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type Store from '@ember-data/store';

import { taskFor } from 'ember-concurrency-ts';
import { dropTask } from 'ember-concurrency-decorators';
import { type TaskGenerator } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import type InsertableAction from 'embercom/models/workflow-connector/insertable-action';

const RECENT_ACTIONS_LIMIT = 5;
const REQUIRED_PERMISSION = 'can_access_developer_hub';

export interface Args {
  suggestedActionsLimit?: number;
}

interface Signature {
  Args: Args;
  Element: HTMLElement;
}

export default class Setup extends Component<Signature> {
  @service declare store: Store;
  @service declare router: RouterService;
  @service declare intercomEventService: $TSFixMe;
  @service declare permissionsService: $TSFixMe;

  @tracked latestActions: InsertableAction[] = [];
  @tracked templateActions: InsertableAction[] = [];
  @tracked totalActionsCount = 0;
  @tracked totalLiveActionsCount = 0;
  @tracked isLoading = true;

  constructor(owner: unknown, args: any) {
    super(owner, args);
    taskFor(this.loadActions).perform();
  }

  @dropTask
  *loadActions(): TaskGenerator<any> {
    let actions: InsertableAction[] = yield this.store.query(
      'workflow-connector/insertable-action',
      {
        unfiltered: true,
      },
    );

    let nonTemplateActions = actions.filterBy('usage', 'fin').filterBy('isTemplate', false);
    let templateActions = actions.filterBy('usage', 'fin').filterBy('isTemplate', true);

    this.totalActionsCount = nonTemplateActions.length;
    this.totalLiveActionsCount = nonTemplateActions.filterBy('state', 'live').length;

    this.latestActions = nonTemplateActions
      .sort(
        (a: InsertableAction, b: InsertableAction) => b.updatedAt.getTime() - a.updatedAt.getTime(),
      )
      .slice(0, RECENT_ACTIONS_LIMIT);

    if (this.args.suggestedActionsLimit !== undefined) {
      this.templateActions = templateActions.slice(0, this.args.suggestedActionsLimit);
    } else {
      this.templateActions = templateActions;
    }

    this.isLoading = false;
  }

  get hasActions() {
    return this.latestActions.length > 0;
  }

  get hasLiveActions() {
    return this.totalLiveActionsCount > 0;
  }

  @action
  async createAction() {
    this.isLoading = true;

    if (!this.validatePermissionAndAllowAction('create-action')) {
      this.isLoading = false;
      return;
    }

    let newWorkflowAction = await this.store
      .createRecord('workflow-connector/action', { usage: 'fin' })
      .save();

    this.intercomEventService.trackAnalyticsEvent({
      action: 'created',
      object: 'custom_action',
      actionId: newWorkflowAction.id,
    });

    this.isLoading = false;
    this.router.transitionTo(
      'apps.app.settings.app-settings.custom-actions.custom-action',
      newWorkflowAction.id,
    );
  }

  validatePermissionAndAllowAction(object: string) {
    if (!this.permissionsService.currentAdminCan(REQUIRED_PERMISSION)) {
      this.permissionsService.loadAllAdminsAndShowPermissionRequestModal(REQUIRED_PERMISSION);
      this.intercomEventService.trackAnalyticsEvent({
        action: 'blocked',
        object,
      });
      return false;
    }
    return true;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'WorkflowConnector::Setup': typeof Setup;
    'workflow-connector/setup': typeof Setup;
  }
}
