/* import __COLOCATED_TEMPLATE__ from './suggested-actions.hbs'; */
/* RESPONSIBLE TEAM: team-customer-data-platform */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { type TaskGenerator } from 'ember-concurrency';
import type InsertableAction from 'embercom/models/workflow-connector/insertable-action';

export interface Args {
  actions: InsertableAction[];
  hasActions: boolean;
  createAction: () => Promise<void>;
  loadActions?: () => TaskGenerator<any>;
}

export default class SuggestedActions extends Component<Args> {
  @service declare intercomEventService: $TSFixMe;

  @tracked showTemplatePreviewModal = false;
  @tracked selectedActionTemplate: InsertableAction | undefined;

  @action
  onClickActionTemplate(action: InsertableAction) {
    this.selectedActionTemplate = action;
    this.showTemplatePreviewModal = true;
    this.intercomEventService.trackAnalyticsEvent({
      action: 'open_template_action_modal',
      object: 'custom_action',
      actionId: action.id,
    });
  }

  @action
  onCloseTemplatePreviewModal() {
    this.showTemplatePreviewModal = false;
    this.selectedActionTemplate = undefined;
    this.intercomEventService.trackAnalyticsEvent({
      action: 'close_template_action_modal',
      object: 'custom_action',
    });
  }

  roundConversationUsage = (action: InsertableAction) => {
    if (!action.conversationUsage) {
      return 0;
    }
    if (action.conversationUsage < 0.5) {
      return 0.5;
    }

    return Math.ceil(action.conversationUsage);
  };
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'WorkflowConnector::Setup::SuggestedActions': typeof SuggestedActions;
    'workflow-connector/setup/suggested-actions': typeof SuggestedActions;
  }
}
